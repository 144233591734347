// src/Blog.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Tweet } from 'react-tweet'

const Blog = () => {
  return (
    <>
      <Header />
      <main className="tweet-container" data-theme="light">
        {/* Row 1 */}
        <Tweet id="1869654079573803183" />
        <Tweet id="1869401939005341930" />
        <Tweet id="1869251093378834661" />
        {/* Row 2 */}
        <Tweet id="1868587829435027699" />
        <Tweet id="1868578826596548848" />
        <Tweet id="1768219661534134274" />

        {/* Row 3 */}
        <Tweet id="1790617608410276101" />
        <Tweet id="1860576174641991914" />
        <Tweet id="1864286386628485365" />

      </main>
      <Footer />
    </>
  );
};

export default Blog;