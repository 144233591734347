import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Cancellation = () => {
  return (
    <>
      <Header />
      <div className="bg-white py-12 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">Service Policies: Upgrades, Cancellations, and Refunds</h1>
          <div className="prose prose-lg">
            <p>
              This document outlines Viszapp's comprehensive policies regarding service upgrades, cancellations, and refunds. 
              We strive to maintain transparency and fairness in all our service arrangements.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Upgrade Options</h2>
            <p>
              If you have purchased our Get Reviewed plan, you have the opportunity to upgrade to our comprehensive A-Z Prep Plan 
              within 7 days of your initial purchase. This upgrade option is available for a flat fee of ₹1,250 and can be accessed 
              directly through your order details section. For additional information or assistance with the upgrade process, 
              please contact our support team at{' '}
              <a href="mailto:support@viszapp.com" className="text-blue-600 hover:text-blue-800">
                support@viszapp.com
              </a>.
            </p>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">Cancellation Process</h2>
            <p>
              To initiate a service cancellation, please email{' '}
              <a href="mailto:support@viszapp.com" className="text-blue-600 hover:text-blue-800">
                support@viszapp.com
              </a>{' '}
              with the following information:
            </p>
            <ul className="list-disc pl-6 mt-2 mb-4">
              <li>Your order ID</li>
              <li>Full name</li>
              <li>Service package details</li>
              <li>Reason for cancellation</li>
            </ul>

            <h2 className="text-2xl font-bold mt-8 mb-4">Cancellation Terms and Fees</h2>
            <div>
              <p className="mb-4">
                1. For cancellations prior to any scheduled calls, a 20% service fee will be deducted from the refund amount.
              </p>
              <p className="mb-4">
                2. After attending the first call, cancellations must be requested within 24 hours with a valid reason to be 
                eligible for a refund. In such cases, a flat cancellation fee of ₹1,250 will apply.
              </p>
              <p className="mb-4">
                3. No-show Policy:
                <ul className="list-disc pl-6 mt-2">
                  <li>A no-show is defined as arriving more than 10 minutes late to a scheduled call</li>
                  <li>No refunds will be provided for no-shows</li>
                  <li>Each no-show will result in the deduction of one session from your package</li>
                  <li>Three no-shows will forfeit the A to Z package</li>
                  <li>One no-show will forfeit the Get Reviewed plan</li>
                  <li>Rescheduling may be considered if requested within 24 hours of the missed appointment</li>
                </ul>
              </p>
            </div>

            <h2 className="text-2xl font-bold mt-8 mb-4">Refund Processing</h2>
            <p>
              Eligible refund requests must be submitted within one working day of service cancellation. All refunds are subject 
              to review and final approval by Viszapp. Please note that a standard 5% processing fee, charged by the payment 
              gateway, will be deducted from all refund amounts.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Contact Information</h2>
            <p>
              For any questions or clarifications regarding these policies, please reach out to our support team at{' '}
              <a href="mailto:support@viszapp.com" className="text-blue-600 hover:text-blue-800">
                support@viszapp.com
              </a>.
            </p>

            <p className="mt-8 text-sm text-gray-600">
              Policy effective as of December 15, 2024. Terms and conditions are subject to change with prior notice.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cancellation;