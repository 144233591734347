import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import {clearAllApplications} from '../redux/slices/visaSlice';
import { clearPricing } from '../redux/slices/pricingSlice';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// import { load } from "@cashfreepayments/cashfree-js";


import config from '../config';

const FinalPage = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [phoneValue, setPhoneValue] = useState('');
const [phoneError, setPhoneError] = useState('');
  // const selectedOption = useSelector((state) => state.pricing.selectedOption);
  const applications = useSelector(state => state.visa.applications);
  const selectedCountries = useSelector(state => state.visa.selectedCountries);
  const selectedPlans = useSelector(state => state.pricing.selectedPlans);

   // eslint-disable-next-line no-unused-vars
  const [bookingIds, setBookingIds] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [promoCode, setPromoCode] = useState('');
  const [promoError, setPromoError] = useState('');
  const [promoApplied, setPromoApplied] = useState(false);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const dispatch = useDispatch();
  

  const handleView = (application) => {
    setSelectedApplication(application);
    setViewModalOpen(true);
  };

  useEffect(() => {
    // Get all applications
    const allApps = Object.values(applications);
    
    // Log each application's appointment details
    allApps.forEach(app => {
      console.log('Appointment Details for:', app.country);
      console.log({
        date: app.appointment.date,
        time: app.appointment.time,
        timeUTC: app.appointment.timeUTC
      });
    });
  }, [applications]); // Dependency array with applications

  const getTotalAmount = () => {
    return selectedCountries.reduce((total, country) => {
      const countryApps = Object.values(applications)
        .filter(app => app.country === country);
      const numApps = countryApps.length;
  
      if (country === 'United States of America Visa') {
        return total + (1500 * numApps);
      } else if (country === 'Japan Visa') {
        return total + (1350 * numApps);
      } else if (country === 'Australia Visa') {
        const plan = selectedPlans[country];
        return total + (plan === 'Get Reviewed' ? 1250 : 2600) * numApps;
      } else {
        const plan = selectedPlans[country];
        return total + (plan === 'Get Reviewed' ? 1250 : 2300) * numApps;
      }
    }, 0);
  };

  const handleApplyPromo = async () => {
    if (!promoCode.trim()) {
      setPromoError('Please enter a promo code');
      return;
    }
  
    try {
      setLoading(true);
      const response = await fetch(`${config.apiBaseUrl}/api/promos/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ code: promoCode })
      });
  
      const data = await response.json();
  
      if (data.status === 'success' && data.valid) {
        setPromoApplied(true);
        setPromoDiscount(data.discount);
        setPromoError('');
        Toast.fire({
          icon: 'success',
          title: `Promo code applied! You save ₹${data.discount}`
        });
      } else {
        setPromoError(data.message || 'Invalid promo code');
      }
    } catch (error) {
      setPromoError('Error validating promo code');
    } finally {
      setLoading(false);
    }
  };
  
  const handleRemovePromo = () => {
    setPromoCode('');
    setPromoApplied(false);
    setPromoDiscount(0);
    setPromoError('');
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
// Add phone change handler
const handlePhoneChange = (newValue) => {
  setPhoneValue(newValue);
  setFormData(prev => ({
    ...prev,
    phone: newValue
  }));
  if (phoneError && isValidPhoneNumber(newValue)) {
    setPhoneError('');
  }
};

const saveAllBookings = async (formData, paymentMethod, calculatedAmount) => {
  // Create details array for each country
  const details = selectedCountries.map(country => {
    const countryApps = Object.values(applications).filter(app => app.country === country);
    const firstApp = countryApps[0];

    return {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      visatype: country,
      drive: firstApp?.documents?.bankStatement || '',
      nation: firstApp?.documents?.nationality || '',
      occ: firstApp?.documents?.occupation || '',
      anything: firstApp?.documents?.additionalInfo || '',
      plan: selectedPlans[country],
      promo: promoApplied ? promoCode : 'None',
      quantity: countryApps.length  // This will be the qty in the database
    };
  });

  // Get appointment data from the first application
  const firstApplication = Object.values(applications)[0];
  // console.log(firstApplication.appointment);
  const appointmentData = {
    datetime: firstApplication?.appointment?.time || '',
    name: formData.name,
    email: formData.email,
    link: 'None',
    status: 'Scheduled'
  };

  // Create the booking data structure
  const bookingData = {
    amount: calculatedAmount,
    details: details, // Array of details, one per country
    appointment: appointmentData,
    payment: {
      amount: calculatedAmount,
      razid: 'pending',
      paymode: paymentMethod,
      status: 'Pending'
    },
    slot: {
      date: firstApplication?.appointment?.date ? formatDateString(firstApplication.appointment.date) : '',
      time: firstApplication?.appointment?.time || ''
    },
    bookStatus: 'Payment Due',
    promo: promoApplied ? promoCode : 'None',
  };

  // console.log('Sending booking data:', bookingData);

  try {
    const response = await fetch(`${config.apiBaseUrl}/api/bookings/book`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(bookingData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to create booking: ${errorData.error || 'Unknown error'}`);
    }

    const responseData = await response.json();
    return [responseData.bookingId]; // Return single bookingId in array for consistency
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};
  

// Helper function to format the date properly
const formatDateString = (dateString) => {
  if (!dateString) return '';
  
  // Parse the ISO date string and extract just the date part
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};
  const CountrySummaryCard = ({ country, applications, appointmentState, plan, onView, onEdit }) => (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4">
      <div className="flex justify-between items-start">
      <div>
        <h3 className="font-semibold text-lg text-[#191983]">{country}</h3>
        <p className="text-sm text-gray-600">
          {country === 'United States of America Visa' 
            ? `${applications.length} Person${applications.length > 1 ? 's' : ''}`
            : `${applications.length} Couple${applications.length > 1 ? 's' : ''} + Dependent`}
        </p>
      </div>
      <div className="text-right">
        <p className="font-medium text-[#191983]">
          ₹{
            country === 'United States of America Visa'
              ? applications.length * 1500
              : country === 'Japan Visa'
                ? applications.length * 1350
                : country === 'Australia Visa'
                  ? plan === 'Get Reviewed'
                    ? applications.length * 1250
                    : applications.length * 2600
                  : plan === 'Get Reviewed'
                    ? applications.length * 1250
                    : applications.length * 2300
          }
        </p>
      </div>
    </div>
  
      {appointmentState?.date && (
        <div className="mt-2 text-sm text-gray-600">
          <div className="flex items-center">
            <span className="mr-2">📅</span>
            <span>{new Date(appointmentState.date).toLocaleDateString()}</span>
            <span className="mx-2">⏰</span>
            <span>
              {new Date(appointmentState.time).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              })}
            </span>
          </div>
        </div>
      )}
  
      {plan && (
        <div className="mt-2 text-sm font-medium text-gray-700">
          Selected Plan: {plan}
        </div>
      )}
  
      <div className="mt-3 flex gap-3">
        <button
          onClick={onView}
          className="px-3 py-1.5 text-sm bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-md flex items-center"
        >
          <span className="mr-1">👁️</span> View Details
        </button>
        <button
          onClick={onEdit}
          className="px-3 py-1.5 text-sm bg-blue-50 hover:bg-blue-100 text-blue-600 rounded-md flex items-center"
        >
          <span className="mr-1">✏️</span> Edit
        </button>
      </div>
    </div>
  );
  
  const ApplicationDetailsModal = ({ isOpen, onClose, application, onEdit }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-xl font-semibold text-[#191983]">
                Application Details for {application.country}
              </h2>
              <button 
                onClick={onClose} 
                className="text-gray-400 hover:text-gray-600 text-2xl leading-none"
              >
                ×
              </button>
            </div>
  
            <div className="space-y-4">
              <div className="border-b pb-4">
                <h3 className="font-medium text-gray-700 mb-2">Document Information</h3>
                <dl className="space-y-2">
                  <div className="flex">
                    <dt className="w-1/3 text-gray-600">Nationality:</dt>
                    <dd className="w-2/3">{application.documents.nationality}</dd>
                  </div>
                  <div className="flex">
                    <dt className="w-1/3 text-gray-600">Occupation:</dt>
                    <dd className="w-2/3">{application.documents.occupation}</dd>
                  </div>
                  {application.documents.bankStatement && (
                    <div className="flex">
                      <dt className="w-1/3 text-gray-600">Bank Statement:</dt>
                      <dd className="w-2/3 break-all">{application.documents.bankStatement}</dd>
                    </div>
                  )}
                  {application.documents.additionalInfo && (
                    <div className="flex">
                      <dt className="w-1/3 text-gray-600">Additional Info:</dt>
                      <dd className="w-2/3">{application.documents.additionalInfo}</dd>
                    </div>
                  )}
                </dl>
              </div>
  
              {/* <div className="border-b pb-4">
                <h3 className="font-medium text-gray-700 mb-2">Selected Plan</h3>
                <p>{application.plan}</p>
                <p className="text-sm text-gray-600 mt-1">
                  {application.plan === 'Get Reviewed' 
                    ? 'Includes document review and one consultation'
                    : 'Includes complete assistance and three consultations'}
                </p>
              </div> */}
            </div>
  
            <div className="mt-6 flex justify-end">
              <button
                onClick={onEdit}
                className="bg-blue-50 text-blue-600 px-4 py-2 rounded-md hover:bg-blue-100 flex items-center"
              >
                <span className="mr-2">✏️</span> Edit Application
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  function formatDateStringForRight(dateString) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  const getApplicationCount = (country) => {
    return Object.values(applications).filter(app => app.country === country).length;
  };

  // Helper function to generate random hex string
const generateRandomId = () => {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};

const handleSubmit = async (data) => {
  setLoading(true);
  // const client = await pool.connect();
  // const dispatch = useDispatch();
  try {
    // Step 1: Calculate total and verify promo
    const calculationResponse = await fetch(
      `${config.apiBaseUrl}/api/payments/calculate-total`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          countries: selectedCountries.map(country => ({
            country,
            quantity: getApplicationCount(country),
            planType: selectedPlans[country]
          })),
          promoCode: promoApplied ? promoCode : null
        })
      }
    );

    if (!calculationResponse.ok) {
      throw new Error('Failed to calculate total');
    }

    const calculationData = await calculationResponse.json();
    const { totalAmount, verificationHash, requiresPayment } = calculationData;
// console.log(calculationData);
    // Step 2: Save initial bookings
    const bookingIds = await saveAllBookings(data, requiresPayment ? "Razorpay" : "Free", totalAmount);
    setBookingIds(bookingIds);

    if (requiresPayment) {
      // Load Razorpay script
      const scriptLoaded = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      
      if (!scriptLoaded) {
        throw new Error('Razorpay SDK failed to load');
      }

      // Create order
      const orderResponse = await fetch(
        `${config.apiBaseUrl}/api/payments/create-order`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          body: JSON.stringify({
            amount: totalAmount * 100,
            email: data.email,
            name: data.name,
            phone: data.phone,
            currency: 'INR',
            verificationHash,
            countries: selectedCountries.map(country => ({
              country,
              quantity: getApplicationCount(country),
              planType: selectedPlans[country]
            })),
            promoCode: promoApplied ? promoCode : null
          })
        }
      );

      if (!orderResponse.ok) {
        throw new Error('Failed to create order');
      }

      const orderData = await orderResponse.json();
      const firstApp = Object.values(applications)[0]; // Get first application to get the appointment time

      const rightTime = `${formatDateStringForRight(firstApp.appointment.date)} ${new Date(firstApp.appointment.time).toLocaleTimeString('en-GB')}`;
      // console.log(rightTime);
      if (orderData.freeOrder) {
        
        // Handle free order
        await verifyPayment({
          razorpay_order_id: orderData.orderId,
          bookingIds,
          rightTime
        });
        
      // Instead of direct dispatches and navigation
      const finishBookingAndRedirect = () => {
        // First navigate
        navigate("/dashboard");
        
        // Then clear states in the next event loop
        setTimeout(() => {
          dispatch(clearAllApplications());
          dispatch(clearPricing());
        }, 0);
      };
      
      // Use this in your success handlers
      Toast.fire({
        icon: "success",
        title: "Booking completed successfully, please wait!",
      }).then(() => {
        finishBookingAndRedirect();
      });
      
        return;
      }

      // Configure Razorpay
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: orderData.amount,
        currency: orderData.currency,
        name: 'Viszapp',
        order_id: orderData.orderId,
        prefill: {
          name: data.name,
          email: data.email,
          contact: data.phone,
        },
        handler: async function(response) {
          try {
            await verifyPayment({
              ...response,
              bookingIds
            });

            const finishBookingAndRedirect = () => {
              // First navigate
              navigate("/dashboard");
              
              // Then clear states in the next event loop
              setTimeout(() => {
                dispatch(clearAllApplications());
                dispatch(clearPricing());
              }, 0);
            };
            
            // Use this in your success handlers
            Toast.fire({
              icon: "success",
              title: "Booking completed successfully, please wait!",
            }).then(() => {
              finishBookingAndRedirect();
            });


          } catch (error) {
            console.error("Payment verification error:", error);
            Toast.fire({
              icon: "error",
              title: "Payment verification failed. Please contact support.",
            });
          }
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      // console.log("Free booking");
      // Handle free booking
      await verifyPayment({
        razorpay_order_id: `FREE_${generateRandomId()}`,
        bookingIds
      });

      // Instead of direct dispatches and navigation
const finishBookingAndRedirect = () => {
  // First navigate
  navigate("/dashboard");
  
  // Then clear states in the next event loop
  setTimeout(() => {
    dispatch(clearAllApplications());
    dispatch(clearPricing());
  }, 0);
};

// Use this in your success handlers
Toast.fire({
  icon: "success",
  title: "Booking completed successfully, please wait!",
}).then(() => {
  finishBookingAndRedirect();
});

    }
  } catch (error) {
    console.error("Error during payment:", error);
    Toast.fire({
      icon: "error",
      title: "Error in payment process. Please try again.",
    });
  } finally {
    setLoading(false);
  }
};

const verifyPayment = async (paymentData) => {
  const firstApp = Object.values(applications)[0]; 

    // Format the date and time from firstApp's appointment
    const formattedDate = new Date(firstApp.appointment.date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    
    const formattedTime = new Date(firstApp.appointment.time).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  
    const rightTime = `${formattedDate} ${formattedTime}`;

  const response = await fetch(
    `${config.apiBaseUrl}/api/payments/verify`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify({
        ...paymentData,
        promoDiscount: promoDiscount,
        rightTime: rightTime
      })
    }
  );

  if (!response.ok) {
    throw new Error('Payment verification failed');
  }

  return await response.json();
};
const validateForm = () => {
  const newErrors = {};
  
  if (!formData.name.trim()) {
    newErrors.name = 'Name is required';
  }
  
  if (!formData.email.trim()) {
    newErrors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    newErrors.email = 'Email is invalid';
  }
  
  if (!phoneValue) {
    setPhoneError('Phone number is required');
    newErrors.phone = 'Phone number is required';
  } else if (!isValidPhoneNumber(phoneValue)) {
    setPhoneError('Invalid phone number');
    newErrors.phone = 'Invalid phone number';
  }
  
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
  
  // Add handle change function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
const Spinner = ({ message }) => (
  <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-5 rounded-lg shadow-lg text-center">
      <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900 mx-auto mb-4"></div>
      <p className="text-lg font-semibold">{message}</p>
    </div>
  </div>
);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
      {loading && <Spinner message="Processing payment... Please don't close this window." />}
      <div className="bg-white rounded-lg m-3 px-3 shadow-lg w-full max-w-3xl overflow-auto max-h-screen">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-[#191983]">
              Review Your Applications
            </h2>
            <button 
  onClick={onClose} 
  className="text-gray-400 hover:text-gray-600 text-2xl leading-none"
>
  ×
</button>
          </div>

          <div className="space-y-4">
            {selectedCountries.map(country => {
              const countryApps = Object.values(applications)
                .filter(app => app.country === country);
              const firstApp = countryApps[0];

              return (
                <CountrySummaryCard
                  key={country}
                  country={country}
                  applications={countryApps}
                  appointmentState={firstApp?.appointment}
                  plan={selectedPlans[country]}
                  onView={() => handleView(firstApp)}
                  onEdit={() => navigate('/visaSelection')}
                />
              );
            })}
          </div>

<div className="mt-8 border-t pt-4">
    {/* Name Input */}
    <div className="mb-6">
    <h3 className="text-lg font-semibold mb-4">Contact Information</h3>
    
    {/* Name Input */}
    <div className="mb-1">
      <div className="flex items-center">
        <span className="font-semibold text-gray-700 w-24">
          Name:
        </span>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="flex-grow m-1 border p-1 rounded-md border-gray-400"
          required
        />
      </div>
      {errors.name && (
        <p className="text-red-500 text-sm ml-24">{errors.name}</p>
      )}
    </div>

    {/* Email Input */}
    <div className="mb-1">
  <div className="flex items-center">
    <span className="font-semibold text-gray-700 w-24">
      Email:
    </span>
    <input
      type="email"
      name="email"
      value={formData.email}
      onChange={handleInputChange}
      className={`flex-grow m-1 border rounded-md p-1 border-gray-400 ${
        errors.email ? "border-red-500" : ""
      }`}
    />
  </div>
  {errors.email && (
    <p className="text-red-500 text-sm ml-24">{errors.email}</p>
  )}
</div>

    {/* Phone Input */}
    <div className="mb-1">
      <div className="flex items-center">
        <span className="font-semibold text-gray-700 w-24">
          Mobile no:
        </span>
        <PhoneInput
          placeholder="Enter phone number"
          international
          countryCallingCodeEditable={false}
          defaultCountry="IN"
          value={phoneValue}
          onChange={handlePhoneChange}
          className="flex-grow m-1 p-1 border rounded-md border-gray-400"
          id="number"
        />
      </div>
      {phoneError && (
        <p className="text-red-500 text-sm ml-24">{phoneError}</p>
      )}
    </div>
  </div>
  <div className="mb-6 pt-2 border-t">
  <div className="flex items-center justify-between">
    <div className="flex items-center">
      <span className="font-semibold text-gray-700 w-24">
        Promo Code:
      </span>
      <div className="flex-grow flex gap-2">
        <input
          type="text"
          placeholder="Enter promo code"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
          className={`flex-grow m-1 border p-1 rounded-md ${
            promoError ? 'border-red-500' : 'border-gray-400'
          }`}
          disabled={promoApplied}
        />
        <button
          onClick={promoApplied ? handleRemovePromo : handleApplyPromo}
          className={`px-4 py-1 rounded-md ${
            promoApplied 
              ? 'bg-red-50 text-red-600 hover:bg-red-100'
              : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
          }`}
        >
          {promoApplied ? 'Remove' : 'Apply'}
        </button>
      </div>
    </div>
  </div>
  {promoError && (
    <p className="text-red-500 text-sm ml-24 mt-1">{promoError}</p>
  )}
  {promoApplied && (
    <div className="ml-24 mt-2 text-sm text-green-600 flex items-center gap-2">
      <span>✓</span>
      <span>Promo code applied! You save ₹{promoDiscount}</span>
    </div>
  )}
</div>             

            {/* Submit Button */}
  <div className="flex justify-between items-center">
  <div>
    <p className="text-lg font-semibold">Total Amount</p>
    {promoApplied && (
      <p className="text-sm text-gray-500 line-through">₹{getTotalAmount()}</p>
    )}
    <p className="text-2xl font-bold text-[#191983]">
      ₹{promoApplied ? getTotalAmount() - promoDiscount : getTotalAmount()}
    </p>
  </div>
  <button
 onClick={() => {
  // console.log(formData);
   if (validateForm()) {
    // console.log("hello");
     handleSubmit(formData);
   }
 }}
 disabled={loading || (promoApplied && (getTotalAmount() - promoDiscount) < 0)}
 className={`bg-[#191983] text-white px-6 py-3 rounded-lg hover:bg-blue-800 transition-colors ${
   loading || (promoApplied && (getTotalAmount() - promoDiscount) < 0) ? 'opacity-50 cursor-not-allowed' : ''
 }`}
>
 {loading ? (
   <span>Processing...</span>
 ) : promoApplied && (getTotalAmount() - promoDiscount) < 0 ? (
   <span>Invalid Amount</span>
 ) : promoApplied && (getTotalAmount() - promoDiscount) === 0 ? (
   <div className="flex items-center gap-2">
     <span>🎉 Surprise! It's Free</span>
   </div>
 ) : (
   <div className="flex items-center gap-2">
     <span>Pay</span>
     <div className="flex items-center gap-1">
       {promoApplied && (
         <span className="text-sm text-gray-300 line-through">₹{getTotalAmount()}</span>
       )}
       <span className="font-semibold">
         ₹{promoApplied ? getTotalAmount() - promoDiscount : getTotalAmount()}
       </span>
     </div>
   </div>
 )}
</button>
  </div>
</div>
        </div>

        <ApplicationDetailsModal
          isOpen={viewModalOpen}
          onClose={() => setViewModalOpen(false)}
          application={selectedApplication}
          onEdit={() => navigate('/attachDocuments')}
        />
      </div>
    </div>
  );
};
export default FinalPage;