import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from "../config";
import Swal from "sweetalert2";
import background from "../assets/loginbackground.webp";
import { useDispatch, useSelector } from "react-redux";

import { setAppointment } from "../redux/slices/visaSlice";
// import { selectApplicationsWithoutAppointments } from '../redux/selectors/visaSelectors';

import axios from "axios";

const AppointmentDate = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applications = useSelector(state => state.visa.applications);
  const selectedCountries = useSelector(state => state.visa.selectedCountries);
  // const applicationsNeedingAppointments = useSelector(selectApplicationsWithoutAppointments);

  // Add new states for managing multiple countries
  const [currentCountry, setCurrentCountry] = useState(selectedCountries[0]);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [appointmentsState, setAppointmentsState] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [error, setError] = useState("");
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    if (!selectedCountries.length) {
      navigate('/visaSelection');
      return;
    }
  }, [selectedCountries, navigate]);

  // Add useEffect to initialize appointments state from Redux
  useEffect(() => {
    if (Object.keys(applications).length > 0) {
      const initialState = {};
      selectedCountries.forEach(country => {
        const countryApp = Object.values(applications)
          .find(app => app.country === country);
        
        if (countryApp?.appointment?.date) {
          initialState[country] = {
            date: countryApp.appointment.date,
            time: countryApp.appointment.time,
            timeUTC: countryApp.appointment.timeUTC,
            isComplete: true
          };
        } else {
          initialState[country] = {
            date: null,
            time: null,
            timeUTC: null,
            isComplete: false
          };
        }
      });
      setAppointmentsState(initialState);
    }
  }, [applications, selectedCountries]);

  // Add this useEffect to handle country changes
useEffect(() => {
  if (currentCountry) {
    const countryApp = Object.values(applications)
      .find(app => app.country === currentCountry);
    
    if (countryApp?.appointment?.date) {
      const appointmentDate = new Date(countryApp.appointment.date);
      const appointmentTime = new Date(countryApp.appointment.time);
      setSelectedDate(appointmentDate);
      setSelectedTime(appointmentTime);
    } else {
      setSelectedDate(null);
      setSelectedTime(null);
    }
  }
}, [currentCountry, applications]);

// Check if country appointment is complete
const isCountryComplete = useCallback((country) => {
  return appointmentsState[country]?.date && appointmentsState[country]?.time;
}, [appointmentsState]);

// Check if all countries are complete
const allCountriesComplete = useCallback(() => {
  return selectedCountries.every(country => isCountryComplete(country));
}, [selectedCountries, isCountryComplete]);


// Remove or modify validateTimeSelection since we don't need to check for conflicts
const validateTimeSelection = useCallback((time) => {
  if (!selectedDate || !time) return { valid: false, message: "Please select a time" };
  
  const currentDateTime = new Date();
  const sixHoursAhead = new Date(currentDateTime.getTime() + 6 * 60 * 60 * 1000);
  const proposedDateTime = new Date(selectedDate);
  proposedDateTime.setHours(time.getHours(), time.getMinutes());

  // Check if proposed time is in the past
  if (proposedDateTime < currentDateTime) {
    return { valid: false, message: "Please select a valid date and time!" };
  }
  
  // Check if proposed time is within 6 hours
  if (proposedDateTime < sixHoursAhead) {
    return { 
      valid: false, 
      message: "You can't book an appointment within 6 hours from now. Please choose a later date/time!"
    };
  }

  return { valid: true };
}, [selectedDate]);

// CountryTab Component for the sidebar
const CountryTab = ({ country, selected, complete, count }) => (
  <button
    onClick={() => {
      setCurrentCountry(country);
      setShowMobileSidebar(false);
    }}
    className={`
      w-full flex items-center justify-between p-4 text-left
      ${selected ? 'bg-blue-100 border-r-4 border-blue-600' : 'hover:bg-gray-50'}
      ${complete ? 'text-green-600' : 'text-gray-700'}
      transition-all duration-200
    `}
  >
    <div className="flex flex-col">
      <span className="font-medium">{country}</span>
      <span className="text-sm text-gray-500">
        {country === 'United States of America Visa' 
          ? `${count} Person${count > 1 ? 's' : ''}` 
          : `${count} Couple${count > 1 ? 's' : ''} + Dependent`}
      </span>
    </div>
    {appointmentsState[country]?.time && (
      <div className="text-sm text-gray-500 text-right">
        <div>{new Date(appointmentsState[country].date).toLocaleDateString()}</div>
        <div>
          {new Date(appointmentsState[country].time).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })}
        </div>
      </div>
    )}
    {complete && (
      <span className="text-green-500 ml-2">✓</span>
    )}
  </button>
);

  const handleInvalidToken = useCallback(
    (message) => {
      // Clear the token from localStorage
      localStorage.removeItem("accessToken");

      // Show a toast message
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: message,
        timer: 3000,
        timerProgressBar: true,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });

      // Redirect to login
      navigate("/login");
    },
    [navigate]
  ); // Memoize handleInvalidToken with navigate

  const fetchAppointments = useCallback(async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        handleInvalidToken("No access token found. Please log in.");
        return;
      }

      const response = await axios.get(
        `${config.apiBaseUrl}/api/slots/next-two-months`,
        {
          headers: {
            'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            Authorization: `Bearer ${token}`
          },
        }
      );

      if (response.data) {
        setAppointments(response.data);
      } else {
        console.error("Unexpected API response structure:", response.data);
        setAppointments([]);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);

      if (error.response?.status === 401 || error.response?.status === 403) {
        handleInvalidToken("Session expired or unauthorized. Please log in.");
      } else {
        setError("Failed to fetch available appointments. Please try again.");
      }
    }
  }, [handleInvalidToken]); // Only include handleInvalidToken as a dependency

  const fetchAvailableTimes = useCallback((date) => {
    const filteredAppointments = appointments.filter(
      (app) => new Date(app.date).toDateString() === date.toDateString()
    );
  
    if (filteredAppointments.length === 0) {
      setError("No available times for the selected date.");
      setAvailableTimes([]);
    } else {
      // Convert API times to Date objects for the selected date
      const times = filteredAppointments.map((app) => {
        const timeObj = new Date(app.time);
        const dateTime = new Date(date);
        dateTime.setHours(timeObj.getHours());
        dateTime.setMinutes(timeObj.getMinutes());
        dateTime.setSeconds(0);
        dateTime.setMilliseconds(0);
        return dateTime;
      });
  
      setAvailableTimes(times);
      setError("");
    }
  }, [appointments]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  useEffect(() => {
    if (selectedDate) {
      fetchAvailableTimes(selectedDate);
    }
  }, [selectedDate, fetchAvailableTimes]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setError("");
    if (date) {
      fetchAvailableTimes(date);
    } else {
      setAvailableTimes([]);
    }
  };

  const handleTimeChange = (time) => {
    if (!time) {
      setError("Please select a time");
      return;
    }
  
    const timeValidation = validateTimeSelection(time);
    if (!timeValidation.valid) {
      setError(timeValidation.message);
      return;
    }
  
    const isValidTime = availableTimes.some((availableTime) => {
      return (
        availableTime.getHours() === time.getHours() &&
        availableTime.getMinutes() === time.getMinutes()
      );
    });
  
    if (isValidTime) {
      const selectedAppointment = appointments.find(app => {
        const appTime = new Date(app.time);
        return appTime.getHours() === time.getHours() && 
               appTime.getMinutes() === time.getMinutes();
      });
  
      if (selectedAppointment) {
        setSelectedTime(time);
        setError("");
      }
    } else {
      setError("Please select a valid time from the available options!");
    }
  };
// Update handleClick (Next button) to handle multiple countries
const handleClick = () => {
  // First check if we have a new selection
  if (!selectedDate || !selectedTime) {
    setError("Please select both date and time for the current country");
    return;
  }

  // Find the selected appointment details
  const selectedAppointment = appointments.find(app => {
    const appTime = new Date(app.time);
    return appTime.getHours() === selectedTime.getHours() && 
           appTime.getMinutes() === selectedTime.getMinutes();
  });

  if (!selectedAppointment) {
    setError("Selected time slot is no longer available");
    return;
  }

  // Save the same appointment for ALL countries
  selectedCountries.forEach(country => {
    const countryApps = Object.entries(applications)
      .filter(([_, app]) => app.country === country);
      
    countryApps.forEach(([appId]) => {
      dispatch(setAppointment({
        applicationId: appId,
        date: selectedDate.toISOString(),
        time: selectedTime.toISOString(),
        timeUTC: selectedAppointment.slotTime
      }));
    });

    // Update local appointment state for each country
    setAppointmentsState(prev => ({
      ...prev,
      [country]: {
        date: selectedDate.toISOString(),
        time: selectedTime.toISOString(),
        timeUTC: selectedAppointment.slotTime,
        isComplete: true
      }
    }));
  });

  // Since we're setting all countries at once, we can navigate directly
  navigate("/choosePrice");
};
  const getUniqueDates = (appointments) => {
    const dates = appointments.map((app) => new Date(app.date).toDateString());
    return [...new Set(dates)].map((date) => new Date(date));
  };

  const availableDates = getUniqueDates(appointments);

  return (
    <div className="relative min-h-screen">
      {/* Background gradient and image */}
      <div
        className="absolute inset-0"
        style={{
          background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))",
        }}
      >
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: `url(${background})`, opacity: 0.08 }}
        ></div>
      </div>
  
      {/* Main layout container */}
      <div className="relative z-10 flex min-h-screen">
        {/* Mobile Toggle Button */}
        {/* Mobile Toggle Button */}
{showMobileSidebar ? (
  <button
    className="md:hidden fixed top-4 right-4 z-50 bg-white p-3 rounded-lg shadow-lg flex items-center justify-center"
    onClick={() => setShowMobileSidebar(false)}
  >
    <span className="text-2xl font-bold">✕</span>
  </button>
) : (
  <button
    className="md:hidden fixed top-4 left-4 z-50 bg-white p-3 rounded-lg shadow-lg flex items-center justify-center"
    onClick={() => setShowMobileSidebar(true)}
  >
    <span className="text-3xl leading-none">☰</span>
  </button>
)}
  
        {/* Sidebar */}
        <div className={`
          ${showMobileSidebar ? 'fixed inset-0 z-40' : 'hidden'} 
          md:relative md:block
          w-full md:w-80 bg-white shadow-lg
        `}>
          <div className="p-4 bg-gray-50 border-b sticky top-0">
            <h3 className="text-lg font-semibold text-gray-800">Selected Countries</h3>
            <p className="text-sm text-gray-500">Choose your appointment date time with us</p>
          </div>
  
          <div className="divide-y overflow-y-auto" style={{ height: 'calc(100vh - 140px)' }}>
            {selectedCountries.map(country => {
              const countryApps = Object.values(applications)
                .filter(app => app.country === country);
              return (
                <CountryTab
                  key={country}
                  country={country}
                  selected={currentCountry === country}
                  complete={isCountryComplete(country)}
                  count={countryApps.length}
                />
              );
            })}
          </div>
  
          <div className="p-4 border-t bg-white sticky bottom-0">
            <div className="text-sm text-gray-500">
              {allCountriesComplete() 
                ? "✅ All appointments completed"
                : `${selectedCountries.filter(c => isCountryComplete(c)).length}/${selectedCountries.length} countries completed`
              }
            </div>
          </div>
        </div>
  
        {/* Main Content Area */}
        <div className="flex-1 p-4 md:p-8 overflow-y-auto">
          <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6">
            <div className="mb-6">
              <h2 className="text-xl sm:text-2xl font-bold text-[#191983] text-center">
                Book Appointment
              </h2>
              <p className="text-gray-600 text-center text-sm mt-2">
                Choose your preferred consultation date and time for all applications
              </p>
            </div>
  
            <div className="flex flex-col items-center space-y-6">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                minDate={new Date()}
                filterDate={(date) => availableDates.some(d => d.toDateString() === date.toDateString())}
                className="w-full"
              />
  
              <div className="w-full">
                <h3 className="text-center text-gray-600 font-medium mb-3">
                  Available Time Slots
                </h3>
                <div className="max-w-md mx-auto bg-white rounded-xl shadow-sm border p-4">
                  <div className="grid grid-cols-3 gap-3 max-h-48 overflow-y-auto px-2">
                    {availableTimes.map((time, index) => (
                      <button
                        key={index}
                        onClick={() => handleTimeChange(time)}
                        className={`
                          py-3 px-4 rounded-lg text-sm font-medium transition-all duration-200
                          ${selectedTime && 
                            selectedTime.getHours() === time.getHours() && 
                            selectedTime.getMinutes() === time.getMinutes()
                            ? 'bg-[#191983] text-white border-2 border-[#191983]'
                            : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border border-gray-200'
                          }
                          focus:outline-none
                        `}
                      >
                        {time.toLocaleTimeString([], { 
                          hour: '2-digit', 
                          minute: '2-digit',
                          hour12: true 
                        })}
                      </button>
                    ))}
                  </div>
                  {availableTimes.length === 0 && selectedDate && (
                    <p className="text-center text-gray-500 py-4">
                      No available time slots for selected date
                    </p>
                  )}
                </div>
                <p className="text-center text-sm text-gray-500 mt-2">
                  All times are shown in your local timezone
                </p>
              </div>
  
              <div className="text-center text-sm text-gray-600">
                This is not the visa or VFS appointment booking, it's a consultation appointment.
              </div>
            </div>
  
            {error && (
              <div className="text-red-500 text-center text-sm font-semibold mt-4 px-4 py-2 bg-red-50 border border-red-200 rounded">
                {error}
              </div>
            )}
  
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={() => navigate("/attachDocuments")}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleClick}
                className="bg-[#191983] text-white px-6 py-2 rounded-lg hover:bg-blue-800 transition-colors"
              >
                Complete & Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDate;
