// store.js
import { configureStore } from '@reduxjs/toolkit';
import visaReducer from './slices/visaSlice';
import pricingReducer from './slices/pricingSlice';

const store = configureStore({
  reducer: {
    visa: visaReducer,
    pricing: pricingReducer
  },
});

export default store;