// visaSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  applications: {}, // Will store applications by unique ID
  selectedCountries: [], // List of selected countries for quick reference
  totalApplications: 0,
  bookedSlots: {}
};

const visaSlice = createSlice({
  name: 'visa',
  initialState,
  reducers: {
    addCountry(state, action) {
      const { country, quantity } = action.payload;
      
      // Create new applications for the country
      for (let i = 0; i < quantity; i++) {
        const applicationId = `${country}-${state.totalApplications + i + 1}`;
        state.applications[applicationId] = {
          country,
          applicationNumber: i + 1,
          documents: {
            bankStatement: '',
            nationality: 'India',
            occupation: 'Salaried',
            additionalInfo: ''
          },
          appointment: {
            date: null,
            time: null,
            timeUTC: null
          }
        };
      }
      
      state.totalApplications += quantity;
      if (!state.selectedCountries.includes(country)) {
        state.selectedCountries.push(country);
      }
    },
    
    removeCountry(state, action) {
      const country = action.payload;
      // Remove all applications for this country
      state.applications = Object.fromEntries(
        Object.entries(state.applications).filter(([_, app]) => app.country !== country)
      );
      // Remove from selected countries
      state.selectedCountries = state.selectedCountries.filter(c => c !== country);
      // Remove from booked slots
      Object.entries(state.bookedSlots).forEach(([date, times]) => {
        const appWithSlot = Object.values(state.applications)
          .find(app => app.country === country && app.appointment?.date === date);
        if (appWithSlot) {
          state.bookedSlots[date] = times.filter(t => t !== appWithSlot.appointment.time);
          if (state.bookedSlots[date].length === 0) {
            delete state.bookedSlots[date];
          }
        }
      });
    },
    
    updateDocuments(state, action) {
      const { applicationId, documents } = action.payload;
      if (state.applications[applicationId]) {
        state.applications[applicationId].documents = {
          ...state.applications[applicationId].documents,
          ...documents
        };
      }
    },
    
    setAppointment(state, action) {
      const { applicationId, date, time, timeUTC } = action.payload;
      if (state.applications[applicationId]) {
        state.applications[applicationId].appointment = {
          date,
          time,
          timeUTC
        };
        // Track booked slot
        if (!state.bookedSlots[date]) {
          state.bookedSlots[date] = [];
        }
        state.bookedSlots[date].push(time);
      }
    },

    // Add a new action to clear appointment
    clearAppointment(state, action) {
      const { applicationId, date, time } = action.payload;
      if (state.applications[applicationId]) {
        state.applications[applicationId].appointment = {
          date: null,
          time: null,
          timeUTC: null
        };
        
        // Remove from booked slots
        if (state.bookedSlots[date]) {
          state.bookedSlots[date] = state.bookedSlots[date].filter(t => t !== time);
          if (state.bookedSlots[date].length === 0) {
            delete state.bookedSlots[date];
          }
        }
      }
    },

    
    clearAllApplications(state) {
      state.applications = {};
      state.selectedCountries = [];
      state.totalApplications = 0;
    }
  }
});

export const {
  addCountry,
  removeCountry,
  updateDocuments,
  setAppointment,
  clearAllApplications
} = visaSlice.actions;

export default visaSlice.reducer;