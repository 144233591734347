import React from 'react';
import PricingCard from '../shared/PricingCard';
import { Tooltip as ReactTooltip } from 'react-tooltip'

const Pricing = () => {
  const getReviewedFeatures = [
    'Reviewing each document and information being shared',
    'Prepare you for the appointment',
    'ONE APPOINTMENT ONLY',
    'Provide the current trends with the targeted countries',
    'Sharpen and improve the application',
    'Email support expires after 48hrs from the scheduled consultation'
  ];

  const azPrepFeatures = [
    `Help you prepare all required documents, including those in the checklist that aren't ready yet`,
    'Prepare complete itinerary for your trip',
    'Dedicated 3 meetings',
    'Review and assist application forms',
    'Unlimited emails and telegram support',
    'Personalized trip recommendation',
    'Travel tips helping you understand the local travel and nuances',
    'Airbnb, flights, card recommendations included'
  ];

  return (
    <div className="flex flex-col items-center bg-white p-4 md:p-10">
<h1 className="text-black text-3xl md:text-5xl font-bold mb-2">Pricing</h1>
<div className="text-gray-600 text-center max-w-2xl mx-auto">
<p className="mb-4 text-sm md:text-base">
  Choose from our two plans below for all countries* 
  <span className="block text-sm mt-1">
    *<strong>Japan</strong> and <strong>Australia</strong> visa consultation: Flat rate ₹1,350 and ₹2,600 (A-Z prep) per person respectively
  </span>
</p>

</div>
      <div className="flex flex-col md:flex-row justify-center space-y-5 md:space-y-0 md:space-x-5">
      <div className="flex-1 flex">
          <PricingCard title="Get Reviewed" subtitle="(recommended for experienced folks)" features={getReviewedFeatures} price="1250" />
        </div>
        <div className="flex-1 flex">
          <PricingCard title="A-Z prep" subtitle="(new-bie or first timers)" features={azPrepFeatures} price="2300" />
        </div>
      </div>
      <div className="mt-7 text-center">
        <h1 className="text-black md:text-2xl font-semibold">
          <span>Countries suported: </span>
          <span data-tooltip-id='Schengen' className='md:text-4xl font-bold'>🇪🇺</span>
          <ReactTooltip id='Schengen' type='success' effect='float'>
            <span className='custom-tooltip'>Schengen</span>
          </ReactTooltip>
          <span data-tooltip-id="UK" className='md:text-4xl font-bold'> 🇬🇧</span>
          <ReactTooltip id='UK' type='success' effect='float'>
          <span className='custom-tooltip'>United Kingdom</span>
          </ReactTooltip>
          <span data-tooltip-id="Canada" className='md:text-4xl font-bold'> 🇨🇦</span>
          <ReactTooltip id='Canada' type='success' effect='float'>
          <span className='custom-tooltip'>Canada</span>
          </ReactTooltip>
          <span data-tooltip-id="Ireland" className='md:text-4xl font-bold'> 🇮🇪</span>
          <ReactTooltip id='Ireland' type='success' effect='float'>
          <span className='custom-tooltip'>Ireland</span>
          </ReactTooltip>
          <span data-tooltip-id="Japan" className='md:text-4xl font-bold'> 🇯🇵</span>
          <ReactTooltip id='Japan' type='success' effect='float'>
          <span className='custom-tooltip'>Japan</span>
          </ReactTooltip>

          <span data-tooltip-id="Australia" className='md:text-4xl font-bold'> 🇦🇺</span>
          <ReactTooltip id='Australia' type='success' effect='float'>
          <span className='custom-tooltip'>Australia</span>
          </ReactTooltip>
        </h1>
        <div className="mt-5 text-center">
        <h1 className="text-black md:text-2xl font-semibold">
          <span>Soon to be supported: </span>
          <span data-tooltip-id="USA" className='md:text-4xl font-bold'> 🇺🇸</span>
          <ReactTooltip id='USA' type='success' effect='float'>
          <span className='custom-tooltip'>United States</span>
          </ReactTooltip>


          

          <span data-tooltip-id="NewZealand" className='md:text-4xl font-bold'> 🇳🇿</span>
          <ReactTooltip id='NewZealand' type='success' effect='float'>
          <span className='custom-tooltip'>New Zealand</span>
          </ReactTooltip>
          <span data-tooltip-id="South Korea" className='md:text-4xl font-bold'> 🇰🇷</span>
          <ReactTooltip id='South Korea' type='success' effect='float'>
          <span className='custom-tooltip'>South Korea</span>
          </ReactTooltip>
          
        </h1>
      </div>
      </div>
      
    </div>
  );
};

export default Pricing;
