import React from 'react';
import facebook from '../assets/instagram.webp'
import twitter from '../assets/x.png'
import { Link } from 'react-router-dom';



const CallToAction = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-[#1F3B4D] w-full  md:w-3/4 my-5 mx-auto rounded-lg lg:pt-0 lg:pb-0 lg:pr-0 lg:pl-4 p-4">
      <div className="flex-1 lg:m-4 lg:px-5 lg:py-3 text-white">
        <p className="text-lg sm:text-xl">Need help?</p>
        <p className="text-lg sm:text-xl">Love travelling, and not the planning? Let Viszapp be your guide to hassle-free journeys. Let's have a quick chat!</p>
      

      </div>
      
      <div className="bg-[#04043C] rounded-l-full rounded-r-lg flex items-center h-full w-full md:w-1/3">
      <button 
  onClick={() => window.open('https://x.com/viszapp', '_blank', 'noopener,noreferrer')}
  className="flex items-center justify-center text-white text-xl font-semibold rounded-full px-6 py-5 lg:py-16 w-full"
>
          Talk to our experts ➔
        </button>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-[#000080] text-white p-4">
      <CallToAction />
    
      <div className="flex flex-col md:flex-row text-lg justify-center md:justify-between mx-auto max-w-7xl">
        <div className="flex flex-col items-center md:items-start m-6 whitespace-nowrap text-center md:text-left">
          <h2 className="text-lg font-bold mb-3">PRODUCTS</h2>
          <Link className="mx-2 text-white text-xl m-1 p-1 hover">Visa</Link>
        </div>
        <div className="flex flex-col items-center md:items-start m-6 text-lg whitespace-nowrap text-center md:text-left">
          <h2 className="text-lg font-bold mb-3">USEFUL LINKS</h2>
          <Link to="/know-us" className="mx-2 text-white text-xl m-1 p-1 hover">
         About Us
        </Link>
        <Link to="/blogs" className="mx-2 text-white text-xl m-1 p-1 hover">
          Blogs
        </Link>
         
          <Link to={'/offers'}><p className="mx-2 text-white text-xl m-1 p-1 hover">Offers</p></Link>
        </div>
        <div className="flex flex-col items-center md:items-start m-6 text-lg whitespace-nowrap text-center md:text-left">
          <h2 className="text-lg font-bold mb-3">OTHERS</h2>
          <a href='/privacy'>
            <p className="m-1">Privacy Policy</p> </a>
            <a href='/terms'>  <p className="m-1">Terms and Conditions</p> </a>
            <a href='/cancellation'>  <p className="m-1">Service Policies</p></a>
          {/* <p className="m-1">Cookies</p> */}
        </div>
        
        <div className="flex flex-col items-center md:items-end m-4 w-full text-lg md:w-2/3 text-center md:text-right">
          <button className="bg-white text-[#000080] font-bold py-2 px-4 rounded-lg mt-4">Viszapp</button>
          <p className="mt-3"></p>
          <a href='mailto:support@viszapp.com' target="_blank" rel="noopener noreferrer"> <p className="mt-3">support@viszapp.com</p></a>
          <p className="mt-3">23, Mahaveer Udhog Nagar, Pali Raj, 306401</p>
          <p className="mt-3">Made with alot of 📑 in 🇮🇳</p>
          <p className="mt-3">©2024 - Viszapp</p>
          
        </div>
        
      </div>
      <div>
        
      </div>
      {/* <div className="text-lg sm:text-xl mx-24 bg-gray-600 w-min p-3 rounded-lg  ">
  <p className="m-1 text-center sm:text-left">Subscribe to NewsLetter</p>
  <div className="flex flex-col sm:flex-row items-center">
    <label htmlFor="email" className="font-semibold mx-2">Email:</label>
    <input type="email" id="email" name="email" placeholder="Enter your email" className="w-full sm:w-auto px-4 mx-2 my-2 sm:my-0 py-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-900" />
    <button className="bg-[#04043C] w-full sm:w-auto mx-2 sm:mx-3 rounded-lg p-2">Subscribe</button>
  </div>
</div> */}
      <div className="flex justify-center lg:justify-start max-w-7xl mx-auto mt-4">
        <a href="https://instagram.com/viszapp"  target="_blank" rel="noopener noreferrer" className="mx-2">
          <img src={facebook} alt="Facebook" className="w-8 h-8"/>
        </a>
        <a href="https://x.com/viszapp" target="_blank" rel="noopener noreferrer" className="mx-2">
          <img src={twitter} alt="Twitter" className="w-8 h-8"/>
        </a>
        {/* <a href="/" className="mx-2">
          <img src={youtube} alt="YouTube" className="w-8 h-8"/>
        </a>
        <a href="/" className="mx-2">
          <img src={linkedin} alt="LinkedIn" className="w-8 h-8"/>
        </a> */}
      </div>
    

      
    </footer>
  );
};

export default Footer;

