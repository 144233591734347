import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const token = localStorage.getItem("accessToken");

        // If no token, redirect to login
        if (!token) {
          handleInvalidToken("No access token found. Please log in.");
          return;
        }

        const response = await axios.get(
          `${config.apiBaseUrl}/api/users/appointments`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Check if response.data.appointments exists and is an array
        if (Array.isArray(response.data.appointments)) {
          setAppointments(response.data.appointments);
        } else {
          console.error("Unexpected API response structure:", response.data);
          setAppointments([]);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching appointments:", error);

        // Handle token-related errors
        if (error.response?.status === 401 || error.response?.status === 403) {
          handleInvalidToken(
            "Session expired or invalid user. Please log in again."
          );
        } else {
          setLoading(false);
        }
      }
    };

    const handleInvalidToken = (message) => {
      // Clear the token from localStorage
      localStorage.removeItem("accessToken");

      // Show a toast message
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: message,
        timer: 3000,
        timerProgressBar: true,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });

      // Redirect to login
      navigate("/login");
    };

    fetchAppointments();
  }, [navigate]);

  const getDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString();
  };
  
  const getTime = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: true 
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl h-full max-h-5xl p-6 lg:p-10 relative">
      <h2 className="text-lg sm:text-2xl font-bold text-black mb-6">
        Upcoming Appointments
      </h2>
      {loading ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-75">
          <div
            className="loader ease-linear rounded-full border-100 border-t-100 h-700 w-700 animate-spin"
            style={{
              borderColor: "bg-[#191983]",
              borderTopColor: "rgba(0, 0, 128, 1)",
            }}
          ></div>
          <p className="mt-4 text-lg text-black font-semibold">
            Please wait while we load latest appointments...
          </p>
        </div>
      ) : appointments.length === 0 ? (
        <p className="text-gray-600">No Appointments yet!</p>
      ) : (
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between">
          {appointments.map((appointment, index) => (
            <div
              key={index}
              className="bg-gray-100 p-3 rounded-lg shadow-lg w-full sm:w-[48%] mb-4"
            >
              <div className="bg-white p-6 rounded-lg shadow-md relative">
                <p className="text-right text-gray-500 absolute text-xs sm:text-base top-2 right-2">
                  Order ID- #{appointment.bid}
                </p>
                <div className="items-center mb-2">
                  <div className="flex pb-3 items-center">
                    <svg
                      className="w-6 h-6 text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="3"
                        y="4"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                        strokeWidth="2"
                      />
                      <line x1="3" y1="10" x2="21" y2="10" strokeWidth="2" />
                      <line
                        x1="8"
                        y1="2"
                        x2="8"
                        y2="6"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <line
                        x1="16"
                        y1="2"
                        x2="16"
                        y2="6"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                    <p className="text-indigo-600 font-bold ml-2">
                      {getDate(appointment.app_date)}
                    </p>
                  </div>
                  <div className="flex items-center pb-2 ml-1">
                    <svg
                      className="w-6 h-6 text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="10" strokeWidth="2" />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6l4 2"
                      />
                    </svg>
                    <div className="flex-grow ml-2">
                      <p className="text-indigo-600 font-bold">
                        {getTime(appointment.app_time)}
                      </p>
                    </div>
                    <p className="text-teal-600 font-bold">
                      {appointment.plan}
                    </p>
                  </div>
                </div>
                <p className="text-base sm:text-lg font-bold mb-2 text-black">
                  {appointment.app_name}
                </p>
                <p className="text-lg sm:text-xl font-bold mb-2 text-black">
                  {appointment.app_name1} for {appointment.visatype}
                </p>
                <p
                  className={`font-semibold mb-4 ${
                    appointment.status === "Confirmed"
                      ? "text-green-600"
                      : "text-green-600"
                  }`}
                >
                  {appointment.status}
                </p>
                <span
                  className="relative inline-block cursor-help"
                  onMouseEnter={() => {
                    if (appointment.link === "None") {
                      setShowTooltip(appointment.bid);
                    }
                  }}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <button
                    className={`py-1 px-4 rounded-lg ${
                      appointment.link === "None"
                        ? "bg-gray-400 text-gray-200"
                        : "bg-indigo-600 text-white"
                    }`}
                    onClick={() => {
                      if (appointment.link !== "None") {
                        window.open(appointment.link, "_blank");
                      } else {
                        alert(
                          "We are yet to confirm your appointment meeting link"
                        );
                      }
                    }}
                    disabled={appointment.link === "None"} // Disable button if link is 'None'
                  >
                    Join
                  </button>
                  {appointment.link === "None" &&
                    showTooltip === appointment.bid && ( // Show tooltip only for the hovered appointment
                      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 w-64 p-2 mb-2 bg-white text-gray-800 text-sm rounded-lg shadow-lg transition-opacity duration-300 z-10">
                        We are confirming your appointment and link will be
                        shared soon.
                      </span>
                    )}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Appointments;
