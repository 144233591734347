// pricingSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pricing: {},  // Store pricing by country
  totalAmount: 0,
  selectedPlans: {} // Store selected plans by country
};

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setPricing(state, action) {
      const { country, amount, plan } = action.payload;
      state.pricing[country] = {
        amount,
        plan
      };
      // Recalculate total
      state.totalAmount = Object.values(state.pricing)
        .reduce((sum, item) => sum + item.amount, 0);
    },
    removeCountryPlan(state, action) {
      const country = action.payload;
      // Remove from pricing
      delete state.pricing[country];
      // Remove from selectedPlans
      delete state.selectedPlans[country];
      // Recalculate total amount
      state.totalAmount = Object.values(state.pricing)
        .reduce((sum, item) => sum + item.amount, 0);
    },
    
    setCountryPlan(state, action) {
      const { country, plan } = action.payload;
      state.selectedPlans[country] = plan;
    },
    
    clearPricing(state) {
      state.pricing = {};
      state.totalAmount = 0;
      state.selectedPlans = {};
    }
  }
});

export const { setPricing, setCountryPlan, clearPricing, removeCountryPlan } = pricingSlice.actions;
export default pricingSlice.reducer;