import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateDocuments } from "../redux/slices/visaSlice";
import config from "../config";

import background from "../assets/loginbackground.webp";

const AttachDocuments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applications = useSelector(state => state.visa.applications);
  const selectedCountries = useSelector(state => state.visa.selectedCountries);
  
  const [error, setError] = useState("");
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(selectedCountries[0]);
  const [documentsState, setDocumentsState] = useState({});

  const [linkValidationLoading, setLinkValidationLoading] = useState(false);

  // Initialize documents state
  useEffect(() => {
    if (!selectedCountries.length) {
      navigate('/visaSelection');
      return;
    }

    const initialState = {};
    selectedCountries.forEach(country => {
      const countryApps = Object.values(applications).filter(app => app.country === country);
      initialState[country] = {
        bankStatement: countryApps[0]?.documents.bankStatement || '',
        nationality: countryApps[0]?.documents.nationality || 'India',
        occupation: countryApps[0]?.documents.occupation || 'Salaried',
        additionalInfo: countryApps[0]?.documents.additionalInfo || '',
        isComplete: false
      };
    });
    setDocumentsState(initialState);
  }, [applications, selectedCountries, navigate]);

  const checkDriveLinkAccess = useCallback(async (link) => {
    try {
      setLinkValidationLoading(true);
      const response = await fetch(
        `${config.apiBaseUrl}/utils/check-link?link=${encodeURIComponent(link)}`
      );
      const data = await response.json();
      setLinkValidationLoading(false);
      return data.accessible;
    } catch (error) {
      console.error("Error accessing the link:", error);
      setLinkValidationLoading(false);
      return false;
    }
  }, []);

  const validateGoogleDriveLink = useCallback((link) => {
    if (!link) return true;
    const driveLinkPattern = /^(https:\/\/)?(www\.)?drive\.google\.com\/.+$/;
    return driveLinkPattern.test(link);
  }, []);

  const isCountryComplete = useCallback((country) => {
    const docs = documentsState[country];
    if (!docs) return false;

    return docs.nationality && 
           docs.occupation && 
           docs.additionalInfo && // Made additional info required
           (!docs.bankStatement || validateGoogleDriveLink(docs.bankStatement));
  }, [documentsState, validateGoogleDriveLink]);


  const allCountriesComplete = useCallback(() => {
    return selectedCountries.every(country => isCountryComplete(country));
  }, [selectedCountries, isCountryComplete]);

  const handleSaveAndContinue = async () => {
    const currentDocs = documentsState[currentCountry];
    
    // Check required fields
    if (!currentDocs?.nationality || !currentDocs?.occupation || !currentDocs?.additionalInfo) {
      setError("Please fill in all required fields");
      return;
    }

    // Validate Google Drive link if provided
    if (currentDocs.bankStatement) {
      if (!validateGoogleDriveLink(currentDocs.bankStatement)) {
        setError("Please enter a valid Google Drive link");
        return;
      }

      const isAccessible = await checkDriveLinkAccess(currentDocs.bankStatement);
      if (!isAccessible) {
        setError("The Google Drive link is not accessible. Please ensure the document is shared properly.");
        return;
      }
    }

    // Save documents for current country
    const countryApps = Object.entries(applications)
      .filter(([_, app]) => app.country === currentCountry);
      
    countryApps.forEach(([appId]) => {
      dispatch(updateDocuments({
        applicationId: appId,
        documents: documentsState[currentCountry]
      }));
    });

    setError("");
    
    // Find next country or navigate
    const currentIndex = selectedCountries.indexOf(currentCountry);
    if (currentIndex < selectedCountries.length - 1) {
      setCurrentCountry(selectedCountries[currentIndex + 1]);
      setShowMobileSidebar(false);
    } else if (allCountriesComplete()) {
      navigate("/appointment");
    } else {
      setError("Please complete documents for all selected countries");
    }
  };

  const CountryTab = ({ country, selected, complete, count }) => (
    <button
      onClick={() => {
        setCurrentCountry(country);
        setShowMobileSidebar(false);
      }}
      className={`
        w-full flex items-center justify-between p-4 text-left
        ${selected ? 'bg-blue-100 border-r-4 border-blue-600' : 'hover:bg-gray-50'}
        ${complete ? 'text-green-600' : 'text-gray-700'}
        transition-all duration-200
      `}
    >
      <div className="flex flex-col">
        <span className="font-medium">{country}</span>
        <span className="text-sm text-gray-500">
          {country === 'United States of America Visa' 
            ? `${count} Person${count > 1 ? 's' : ''}` 
            : `${count} Couple${count > 1 ? 's' : ''} + Dependent`}
        </span>
      </div>
      {complete && (
        <span className="text-green-500">✓</span>
      )}
    </button>
  );

  return (
    <div className="relative min-h-screen">
    {/* Background gradient and image */}
    <div
      className="absolute inset-0"
      style={{
        background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))",
      }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${background})`, opacity: 0.08 }}
      ></div>
    </div>
      {/* Mobile Toggle Button */}
      <div className="relative z-10 flex flex-col md:flex-row min-h-screen">
{/* Mobile Toggle Button */}
{showMobileSidebar ? (
  <button
    className="md:hidden fixed top-4 right-4 z-50 bg-white p-3 rounded-lg shadow-lg flex items-center justify-center"
    onClick={() => setShowMobileSidebar(false)}
  >
    <span className="text-2xl font-bold">✕</span>
  </button>
) : (
  <button
    className="md:hidden fixed top-4 left-4 z-50 bg-white p-3 rounded-lg shadow-lg flex items-center justify-center"
    onClick={() => setShowMobileSidebar(true)}
  >
    <span className="text-3xl leading-none">☰</span>
  </button>
)}

      {/* Sidebar */}
      <div className={`
        ${showMobileSidebar ? 'fixed inset-0 z-40' : 'hidden'} 
        md:relative md:block
        w-full md:w-80 bg-white shadow-lg
        transition-all duration-300 ease-in-out
      `}>
        <div className="p-4 bg-gray-50 border-b sticky top-0">
          <h3 className="text-lg font-semibold text-gray-800">Selected Countries</h3>
          <p className="text-sm text-gray-500">Complete documents for each country</p>
        </div>
        
        <div className="divide-y overflow-y-auto" style={{ height: 'calc(100vh - 140px)' }}>
          {selectedCountries.map(country => {
            const countryApps = Object.values(applications)
              .filter(app => app.country === country);
            return (
              <CountryTab
                key={country}
                country={country}
                selected={currentCountry === country}
                complete={isCountryComplete(country)}
                count={countryApps.length}
              />
            );
          })}
        </div>

        <div className="p-4 border-t bg-white sticky bottom-0">
          <div className="text-sm text-gray-500">
            {allCountriesComplete() 
              ? "✅ All documents completed"
              : `${selectedCountries.filter(c => isCountryComplete(c)).length}/${selectedCountries.length} countries completed`
            }
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-4 md:p-8">
        <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6">
          <div className="mb-6">
            <h2 className="text-2xl font-bold text-[#191983]">
              Documents for {currentCountry}
            </h2>
            <p className="text-gray-600">
              Please complete the documents for this country's application
            </p>
          </div>

          <form onSubmit={e => e.preventDefault()} className="space-y-6">
          <div>
              <label className="block font-semibold text-gray-700 mb-2">
                Upload Documents (Google Drive Link - Optional):
                {linkValidationLoading && (
                  <span className="ml-2 inline-block">
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-blue-500 border-t-transparent"></div>
                  </span>
                )}
              </label>
              <input
                type="text"
                value={documentsState[currentCountry]?.bankStatement || ''}
                onChange={(e) => setDocumentsState(prev => ({
                  ...prev,
                  [currentCountry]: {
                    ...prev[currentCountry],
                    bankStatement: e.target.value
                  }
                }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Paste Google Drive link"
              />
              {documentsState[currentCountry]?.bankStatement && 
               !validateGoogleDriveLink(documentsState[currentCountry].bankStatement) && (
                <p className="text-sm text-red-500 mt-1">
                  Please enter a valid Google Drive link
                </p>
              )}
            </div>

            <div>
              <label className="block font-semibold text-gray-700 mb-2">
                Nationality: <span className="text-red-500">*</span>
              </label>
              <select
                value={documentsState[currentCountry]?.nationality || ''}
                onChange={(e) => setDocumentsState(prev => ({
                  ...prev,
                  [currentCountry]: {
                    ...prev[currentCountry],
                    nationality: e.target.value
                  }
                }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                <option value="">Select Nationality</option>
                <option value="India">India</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div>
              <label className="block font-semibold text-gray-700 mb-2">
                Occupation: <span className="text-red-500">*</span>
              </label>
              <select
                value={documentsState[currentCountry]?.occupation || ''}
                onChange={(e) => setDocumentsState(prev => ({
                  ...prev,
                  [currentCountry]: {
                    ...prev[currentCountry],
                    occupation: e.target.value
                  }
                }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                <option value="Salaried">Salaried</option>
                <option value="Self-Employed">Self-Employed</option>
                <option value="Student">Student</option>
                <option value="Retired">Retired</option>
                <option value="Unemployed">Unemployed</option>
              </select>
            </div>

            <div>
              <label className="block font-semibold text-gray-700 mb-2">
                Additional Information: <span className="text-red-500">*</span>
              </label>
              <textarea
                value={documentsState[currentCountry]?.additionalInfo || ''}
                onChange={(e) => setDocumentsState(prev => ({
                  ...prev,
                  [currentCountry]: {
                    ...prev[currentCountry],
                    additionalInfo: e.target.value
                  }
                }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md h-24"
                placeholder="Enter details about your travel plans, dates, and requirements"
                required
              />
              <p className="text-sm text-gray-500 mt-1">
                Please provide: tentative travel dates, specific visa requirements, past visa history (if any), 
                and any other relevant details about your travel plans
              </p>
              {documentsState[currentCountry]?.additionalInfo === '' && (
                <p className="text-sm text-red-500 mt-1">
                  This field is required
                </p>
              )}
            </div>

            {error && (
              <div className="p-3 bg-red-100 text-red-700 rounded-lg">
                {error}
              </div>
            )}

            <div className="flex justify-between pt-4">
              <button
                type="button"
                onClick={() => navigate("/visaSelection")}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleSaveAndContinue}
                className="bg-[#191983] text-white px-6 py-2 rounded-lg hover:bg-blue-800 transition-colors"
              >
                {currentCountry === selectedCountries[selectedCountries.length - 1] && allCountriesComplete()
                  ? "Complete & Continue"
                  : "Save & Next Country"}
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>
    </div>
  );
};

export default AttachDocuments;